<template>
  <div class="row template-links">
    <div class="col-12 d-flex mb-5">
      <base-button
        type="link"
        :class="activeMenu==routeNameTeam ? 'active' : ''"
        @click="()=>{$router.push({name: routeNameTeam})}"
      >Recruiters
      </base-button>

      <base-button
        type="link"
        :class="activeMenu==routeNameHiringManagers ? 'active' : ''"
        @click="()=>{$router.push({name: routeNameHiringManagers})}"
      >Hiring Managers
      </base-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'team-header-menu',
  props: {
    activeMenu: {
      type: String,
      default: '',
      description: 'Active Menu name (Recruiters or Hiring Managers)',
    },
  },
  data: function() {
    const routeNameTeam = 'Team';
    const routeNameHiringManagers = 'Hiring Managers';
    return {
      routeNameTeam,
      routeNameHiringManagers,
    };
  },
  methods: {},
};
</script>

<style lang="scss">
  .template-links {
    .btn-link.active {
      border-bottom: 2px solid;
      border-radius: 0;
    }
    .btn-link {
      box-shadow: none !important;
    }
  }
</style>
