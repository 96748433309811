<template>
  <card :class="{'hiring-manager-code' : isTeamHiringManagers}">
    <template slot="header">
      <h3 class="mb-0" v-if="!isTeamHiringManagers">Get your Keeyora number</h3>
    </template>
    <template>
      <div class="d-flex flex-column">
        <p v-if="!isTeamHiringManagers && accountType === accountTypes.TEAM && userType === userTypes.ADMIN"
           class="font-600">By getting a number you will fill one of your available seats.</p>
        <p v-if="!isTeamHiringManagers" class="font-600">We have numbers in all states and most area codes.</p>
        <ValidationObserver v-slot="{ invalid, validate }" ref="formValidator">
          <form
            @submit.prevent="validate().then(findPhoneNumber)"
            class="needs-validation"
          >
            <div class="area-code d-flex align-items-start">
              <base-input
                label="Area Code"
                placeholder="###"
                v-model="areaCode"
                rules="required|numeric|min:3"
                maxLength="3"
                :showErrors="false"
                class="mt-1"
                @input="emitAreaCodeChanged"
              />
              <div class="ml-1 spacer-2">
                <base-button
                  type="primary"
                  :disabled="invalid"
                  :loading="numbersSearching"
                  nativeType="submit"
                >Find number
                </base-button
                >
              </div>
            </div>
          </form>
        </ValidationObserver>
        <div v-if="phonesNumbers.length">
          <app-select
            :options="phonesOptionsList"
            v-model="phone"
            label="Phone number"
            placeholder="Select phone number"
            class="phone-numbers-list"
            @input="notifyPhoneSelected"
          ></app-select>
        </div>
        <div class="d-flex justify-content-end mt-6" v-if="!isTeamHiringManagers">
          <base-button type="outline-primary" @click="$router.push({name: 'Home'})">Skip for now</base-button>
          <base-button type="primary" :disabled="!phone" @click="tryBuyNumber">Start texting</base-button>
        </div>
      </div>
    </template>
  </card>
</template>
<script>
import BaseButton from '@/components/BaseButton.vue';
import BaseInput from '@/components/Inputs/BaseInput.vue';
import {findPhoneNumberApi} from '@/api/seatApi';
import AppSelect from '@/components/App/controls/AppSelect';
import {mapActions, mapGetters} from 'vuex';
import {BUY_PHONE_NUMBER_REQUEST} from '@/store/storeActions';
import appNotification from '@/util/appNotification';
import {accountTypes, userTypes} from '@/store/userConstants';

export default {
  components: {
    BaseInput,
    BaseButton,
    AppSelect,
  },
  props: {
    changeScreenHandler: {
      type: Function,
    },
    isTeamHiringManagers: {
      type: Boolean,
    },
  },
  watch: {
    areaCode() {
      // Clear phone numbers when area code changes
      this.phonesNumbers = [];
    },
  },
  data() {
    return {
      areaCode: '',
      numbersSearching: false,
      phonesNumbers: [],
      phone: '',
      accountTypes,
      userTypes,
    };
  },
  computed: {
    ...mapGetters([
      'accountType',
      'userType',
    ]),
    phonesOptionsList: function() {
      return this.phonesNumbers.map((phone) => ({
        label: phone.friendlyName,
        value: phone.phoneNumber,
      }));
    },
  },
  methods: {
    ...mapActions([
      BUY_PHONE_NUMBER_REQUEST,
    ]),
    emitAreaCodeChanged() {
      this.$emit("area-code-changed"); // Notify parent when area code changes
    },
    notifyPhoneSelected() {
      if(this.isTeamHiringManagers && this.phone) {
        // Emit the phone number selected to the parent
        this.$emit('phone-selected', this.phone);
      } 
    },
    findPhoneNumber: async function() {
      if (!this.areaCode) return;
      this.numbersSearching = true;
    
      this.phone = '';
      try {
        const resNumbers = await findPhoneNumberApi(this.areaCode);
        this.phonesNumbers = resNumbers;
      } catch (e) {
      } finally {
        if (this.phonesNumbers.length) {
          if(this.isTeamHiringManagers) {
            this.phone = null;
          } else {
            this.phone = this.phonesNumbers[0].phoneNumber;
          }
        } else {
          appNotification.notify({message: 'Try a different area code'});
        }
        ;
        this.numbersSearching = false;
      }
    },
    tryBuyNumber() {
      this.phone && this.BUY_PHONE_NUMBER_REQUEST({data: {phoneNumber: this.phone}, redirect: 'Account'});
    },
  },
};
</script>
<style lang="scss">
.area-code {
  input[type="text"] {
    width: 90px !important;
  }
  button.btn {
    padding: 12px 16px !important;
  }
}
.area-code {
  height: 99px;
}

.spacer-2 {
  margin-top: 2rem !important;
}
.card.hiring-manager-code {
  border: none !important;
  box-shadow: none !important;
  margin-bottom: 0px;
}
.card.hiring-manager-code .card-body {
  padding: 0px !important;
}
.phone-numbers-list .select-wrapper {
  max-height: 154px !important;
}
</style>
